







































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { PageMode } from '@/utils/type'
import MaterialSelect from '@/components/material-select/index.vue'
import LiveUpload from '@/components/live-broadcast/live-upload.vue'
import { apiLiveRoomAdd } from '@/api/application/live_broadcast'
import { throttle } from '@/utils/util'
@Component({
    components: {
        MaterialSelect,
        LiveUpload
    }
})
export default class LiveBroadcastEdit extends Vue {
    pickerOptions = {
        shortcuts: [
            {
                text: '今天',
                onClick(picker: any) {
                    picker.$emit('pick', new Date())
                }
            },
            {
                text: '昨天',
                onClick(picker: any) {
                    const date = new Date()
                    date.setTime(date.getTime() - 3600 * 1000 * 24)
                    picker.$emit('pick', date)
                }
            },
            {
                text: '一周前',
                onClick(picker: any) {
                    const date = new Date()
                    date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
                    picker.$emit('pick', date)
                }
            }
        ]
    }

    mode: string = PageMode.ADD // 当前页面【add: 添加 | edit: 编辑】

    form = {
        type: 0, //【1: 推流，0：手机直播】
        name: '', // 直播间名称
        start_time: 0, // 直播开始时间
        end_time: 0, // 直播结束时间
        anchor_name: '', // 主播名称
        anchor_wechat: '', // 主播微信号 wapftp
        // sub_anchor_wechat: 'wapftp', // 主播副号微信号
        cover_img: '', // 背景图（调用上传素材接口， 拿返回的media_id）
        share_img: '', // 分享图（调用上传素材接口， 拿返回的media_id）
        feeds_img: '', // 购物直播频道封面图（调用上传素材接口， 拿返回的media_id）
        is_feeds_public: 0, // 是否开启官方收录 【1: 开启，0：关闭】，默认开启收录
        close_like: 0, // 是否关闭点赞 【0：开启，1：关闭】（若关闭，观众端将隐藏点赞按钮，直播开始后不允许开启）
        close_goods: 0, // 是否关闭货架 【0：开启，1：关闭】（若关闭，观众端将隐藏商品货架，直播开始后不允许开启）
        close_comment: 0, // 是否关闭评论 【0：开启，1：关闭】（若关闭，观众端将隐藏评论入口，直播开始后不允许开启）
        close_replay: 0, // 是否关闭回放 【0：开启，1：关闭】默认关闭回放（直播开始后允许开启）
        close_share: 0, // 是否关闭分享 【0：开启，1：关闭】默认开启分享（直播开始后不允许修改）
        close_kf: 0 // 是否关闭客服 【0：开启，1：关闭】 默认关闭客服（直播开始后允许开启）
    }

    start = '' // 直播开始时间
    end = '' // 直播结束时间

    formRules = {
        type: [
            {
                required: true,
                message: '请选择直播类型',
                trigger: 'change'
            }
        ],
        name: [
            {
                required: true,
                message: '请输入直播标题',
                trigger: 'blur'
            }
        ],
        start_time: [
            {
                required: true,
                message: '请选择直播开始时间',
                trigger: 'change'
            }
        ],
        end_time: [
            {
                required: true,
                message: '请选择直播结束时间',
                trigger: 'change'
            }
        ],
        anchor_name: [
            {
                required: true,
                message: '请输入主播昵称',
                trigger: 'blur'
            }
        ],
        anchor_wechat: [
            {
                required: true,
                message: '请输入主播微信号',
                trigger: 'blur'
            }
        ],
        cover_img: [
            {
                required: true,
                message: '请选择直播间背景墙图片',
                trigger: 'blur'
            }
        ],
        share_img: [
            {
                required: true,
                message: '请选择分享卡片封面图片',
                trigger: 'blur'
            }
        ],
        feeds_img: [
            {
                required: true,
                message: '请选择直播卡片封面图片',
                trigger: 'blur'
            }
        ]
    }
    $refs!: {
        formRef: any
    }

    setCoverImg(val: any) {
        this.form.cover_img = val
    }
    setShareImg(val: any) {
        this.form.share_img = val
    }
    setFeedsImg(val: any) {
        this.form.feeds_img = val
    }

    // 表单提交
    onSubmit() {
        // 验证表单格式是否正确
        this.$refs.formRef.validate((valid: boolean): any => {
            if (!valid) {
                return
            }
            // 请求发送
            // switch (this.mode) {
            // 	case PageMode['ADD']:
            // 		return this.handleUserLabelAdd()
            // 	case PageMode['EDIT']:
            // 		return this.handleUserLabelEdit()
            // }
            this.liveRoomAdd()
        })
    }

    // 创建直播间
    liveRoomAdd() {
        const dateStart = new Date(this.start)
        this.form.start_time = dateStart.getTime() / 1000
        const dateEnd = new Date(this.end)
        this.form.end_time = dateEnd.getTime() / 1000

        apiLiveRoomAdd(this.form)
            .then((res: any) => {
                setTimeout(() => this.$router.go(-1), 500)
            })
            .catch((err: any) => {})
    }

    created() {
        this.onSubmit = throttle(this.onSubmit, 1000)
    }
}
