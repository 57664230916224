


















import { Component, Vue, Prop } from 'vue-property-decorator'
import config from '@/config'
@Component({
    components: {}
})
export default class LsUpload extends Vue {
    // @Prop() value ? : string // imgUrl

    action = `${config.baseURL}/adminapi/upload/wechatMaterial`
    version = config.version
    ishide = false

    handleRemove(file: any, fileList: any) {
        this.ishide = false
    }
    handleSuccess(response: any, file: any, fileList: any[]) {
        this.$emit('setImage', response.data.media_id)
        this.ishide = true
    }
    handleError(err: any, file: any) {
        this.$message.error(`${file.name}文件上传失败`)
    }
    handleExceed() {
        this.$message.error('超出上传上限，请重新上传')
    }
}
